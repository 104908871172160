.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: "LarkenBlack";   /*Can be any text*/
  src: local("LarkenBlack"),
    url("assets/fonts/larken/LarkenBlack.otf") format("truetype");
}

@font-face {
  font-family: "LarkenBlackItalic";   /*Can be any text*/
  src: local("LarkenBlackItalic"),
    url("assets/fonts/larken/LarkenBlackItalic.otf") format("truetype");
}

@font-face {
  font-family: "LarkenBold";   /*Can be any text*/
  src: local("LarkenBold"),
    url("assets/fonts/larken/LarkenBold.otf") format("truetype");
}

@font-face {
  font-family: "LarkenBoldItalic";   /*Can be any text*/
  src: local("LarkenBoldItalic"),
    url("assets/fonts/larken/LarkenBoldItalic.otf") format("truetype");
}

@font-face {
  font-family: "LarkenExtraBold";   /*Can be any text*/
  src: local("LarkenExtraBold"),
    url("assets/fonts/larken/LarkenExtraBold.otf") format("truetype");
}

@font-face {
  font-family: "LarkenExtraBoldItalic";   /*Can be any text*/
  src: local("LarkenExtraBoldItalic"),
    url("assets/fonts/larken/LarkenExtraBoldItalic.otf") format("truetype");
}

@font-face {
  font-family: "LarkenItalic";   /*Can be any text*/
  src: local("LarkenItalic"),
    url("assets/fonts/larken/LarkenItalic.otf") format("truetype");
}

@font-face {
  font-family: "LarkenLight";   /*Can be any text*/
  src: local("LarkenLight"),
    url("assets/fonts/larken/LarkenLight.otf") format("truetype");
}

@font-face {
  font-family: "LarkenLightItalic";   /*Can be any text*/
  src: local("LarkenLightItalic"),
    url("assets/fonts/larken/LarkenLightItalic.otf") format("truetype");
}

@font-face {
  font-family: "LarkenMedium";   /*Can be any text*/
  src: local("LarkenMedium"),
    url("assets/fonts/larken/LarkenMedium.otf") format("truetype");
}

@font-face {
  font-family: "LarkenMediumItalic";   /*Can be any text*/
  src: local("LarkenMediumItalic"),
    url("assets/fonts/larken/LarkenMediumItalic.otf") format("truetype");
}

@font-face {
  font-family: "LarkenRegular";   /*Can be any text*/
  src: local("LarkenRegular"),
    url("assets/fonts/larken/LarkenRegular.otf") format("truetype");
}

@font-face {
  font-family: "LarkenThin";   /*Can be any text*/
  src: local("LarkenThin"),
    url("assets/fonts/larken/LarkenThin.otf") format("truetype");
}

@font-face {
  font-family: "LarkenThinItalic";   /*Can be any text*/
  src: local("LarkenThinItalic"),
    url("assets/fonts/larken/LarkenThinItalic.otf") format("truetype");
}

@font-face {
  font-family: "InterRegular";   /*Can be any text*/
  src: local("InterRegular"),
    url("assets/fonts/Inter/InterRegular.ttf") format("truetype");
}

@font-face {
  font-family: "InterItalic";   /*Can be any text*/
  src: local("InterItalic"),
    url("assets/fonts/Inter/InterItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Liana";   /*Can be any text*/
  src: local("Liana"),
    url("assets/fonts/liana/Liana.ttf") format("truetype");
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#payment-element {
  margin-bottom: 24px;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

.cutive-mono-regular {
  font-family: "Cutive Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.arrows {
  width: 60px;
  height: 72px;
  left: 50%;
  bottom: 20px;
}

.arrows path {
  fill: transparent;
  stroke-width: 1px;  
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite; 
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
  animation-delay:-1s;
  -webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay:-0.5s;
  -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 { 
  animation-delay:0s;
  -webkit-animation-delay:0s; /* Safari 和 Chrome */
}